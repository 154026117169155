import { ChangeEvent, MutableRefObject, RefObject, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../context/AppContextProvider";
import i18next, { t } from "i18next";
import i18n from "../i18n";
import { Language, languages } from "../languages/languages";
import { Trans, useTranslation } from "react-i18next";



export default function LangSwitcher() {
    const { language } = i18n;

    const currentLanguage = languages.filter(e => e.value === language)
    // const [language, setLanguage] = useState(state.language);
    const [selectedOption, setSelectedOption] = useState<Language>(currentLanguage[0]);
    const [areOptionsOpen, setAreOptionsOpen] = useState(false);

    const handleLangChange = (e: ChangeEvent<HTMLSelectElement>) => {
        //setLanguage(e.target.value);
        i18next.changeLanguage(e.target.value)
    }

    const select = useRef<HTMLDivElement>(null);

    function useOutsideAlerter(ref: RefObject<HTMLDivElement>) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event: MouseEvent) {
                if (ref.current && !ref.current.contains(event.target as Node)) {
                    setAreOptionsOpen(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(select);

    useEffect(() => {
        i18next.changeLanguage(selectedOption?.value || "it")
    }, [selectedOption]);

    useEffect(() => {
        setSelectedOption(languages.filter((o) => o.value === language)[0]);
    }, [language]);

    return (
        <div ref={select} className="custom-select" role="select">
            <button
                className="custom-select-opener transition-all duration-200"
                type="button"
                onClick={() => setAreOptionsOpen((i) => !i)}
            >
                <div
                    style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "24px",
                        display: "flex",
                        alignItems: "center",
                        color: "#30374A",
                        gap: "10px",
                    }}
                >
                    {selectedOption?.icon}
                    <label className="cursor-pointer">{t(`languageLabel.${selectedOption?.value}`)}</label>
                </div>
                <svg
                    style={{
                        rotate: areOptionsOpen ? "180deg" : "0deg",
                        transition: "all .1s ease-in-out",
                    }}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5 7.96378L10 12.9638L15 7.96378"
                        stroke="#5E7499"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
            {/* OPTIONS CONTAINER */}
            <div
                className={`custom-select-options-container z-10 ${areOptionsOpen ? "opened" : ""}`}
            >
                <div>
                    {languages.map((o) => {
                        const languageLabel = t(`languageLabel.${o.value}`)
                        return (
                            <button
                                key={o.value}
                                className="custom-select-option"
                                type="button"
                                onClick={() => {
                                    setSelectedOption(o);
                                    setAreOptionsOpen(false);
                                }}
                            >
                                {o.icon}
                                {languageLabel}
                            </button>
                        );
                    })}
                </div>
            </div>
        </div >
    );
}