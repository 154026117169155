
const baseUrl = process.env.REACT_APP_PROD_URL || process.env.REACT_APP_STAGING_URL || "https://documentale.api.cosmogas.com";

export async function getProduct(serial: string) {
    try {
        const url = `${baseUrl}/api/v1/productInstances/${serial}/public`;
        const res = await fetch(url);
        if (res.status !== 200) {
            throw Error(`Internal server error: ${res.statusText}`)
        }

        const json = await res.json();

        if (!json.product) {
            console.log("No product found");
            return;
        }

        return json;
    } catch (error) {
        console.log(error);
        return;
    }
}

export async function getDocs(serial: string) {
    try {
        const url = `${baseUrl}/api/v1/productInstances/${serial}/documents/public?take=100`;
        const res = await fetch(url);

        if (res.status !== 200) {
            throw Error(`Internal server error: ${res.statusText}`)
        }

        const json = await res.json();

        if (!json.data) {
            console.log("No manuals found");
            return;
        }

        return json;
    } catch (error) {
        console.log(error);
        return;
    }
}