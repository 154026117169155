import { Product, Doc } from "../@types/types";

export enum ActionTypes {
    ChangeCategory,
    LoadDocs,
    LoadProduct
}

export interface ChangeCategory {
    type: ActionTypes.ChangeCategory,
    payload: string,
}

export interface LoadDocs {
    type: ActionTypes.LoadDocs,
    payload: Doc[],
}

export interface LoadProduct {
    type: ActionTypes.LoadProduct,
    payload: Product,
}

export type AppActions = ChangeCategory | LoadDocs | LoadProduct;