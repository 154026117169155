import { Doc, Product } from "../@types/types";
import { Category } from "../enums/enums";

export interface AppState {
    selectedCategory: string | null,
    docs: Doc[],
    product: Product,
}

export const initialAppState: AppState = {
    product: {
        seriale: null,
        prodotto: null,
        modello: null,
        codice_articolo: null,
        data_fine_costruzione: null,
        immagine: null,
    },
    selectedCategory: null,
    docs: []
}