import { useContext, useEffect, useState } from "react"
import Logo from "./Logo"
import { AppContext } from "../context/AppContextProvider";
import LangSwitcher from "./LangSwitcher";
import Footer from "./Footer";
import { ActionTypes } from "../actions/actions";

export default function Container(props: { children: JSX.Element }) {
    const { state, dispatch } = useContext(AppContext);

    useEffect(() => {
        const params = window.location.search;
        const urlParams = new URLSearchParams(params);
        const seriale = urlParams.get("seriale");

        console.log(seriale)
        dispatch({
            type: ActionTypes.LoadProduct,
            payload: { ...state.product, seriale: seriale },
        })
    }, [])

    return <>
        <div className="wrapper">
            {
                process.env.REACT_APP_ENV === "dev" &&
                <div className="hidden md:block absolute left-[5%] md:left-[10%] md:pt-[106px] z-10">
                    <LangSwitcher />
                </div>
            }
            <Logo />
            <main className="content px-6 md:px-4 pb-[130px] md:pb-[100px]">
                <div className="container">
                    {props.children}
                </div>
            </main >
            <Footer />
        </div >
    </>
}