import { createContext, useReducer } from "react";
import { AppState, initialAppState } from "../state/state";
import { ActionTypes, AppActions } from "../actions/actions";

export const AppContext = createContext<{
    state: AppState;
    dispatch: React.Dispatch<AppActions>;
}>({
    state: initialAppState,
    dispatch: () => undefined,
});

export default function AppContextProvider(props: { children: JSX.Element }) {
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialAppState);

    return <>
        <AppContext.Provider value={{ state, dispatch }}>
            {children}
        </AppContext.Provider>
    </>
}

function reducer(state: AppState, action: AppActions): AppState {
    switch (action.type) {
        case ActionTypes.ChangeCategory:
            return { ...state, selectedCategory: action.payload }
        case ActionTypes.LoadDocs:
            return { ...state, docs: action.payload }
        case ActionTypes.LoadProduct:
            return { ...state, product: action.payload }
        default:
            throw new Error("Undefined action");
    }
}