import { Trans } from "react-i18next";
import { t } from "i18next";
import { useContext, useEffect } from "react";
import { AppContext } from "../context/AppContextProvider";
import { ActionTypes } from "../actions/actions";
import { getProduct } from "../api/api";

interface ProductCardProps {
    title: string,
    serial: string,
    modelNumber: string,
    imageSrc: string,
}

export function ProductCard() {
    const { state, dispatch } = useContext(AppContext);
    const { product } = state;
    const { seriale, prodotto, modello, codice_articolo, data_fine_costruzione, immagine } = product;

    useEffect(() => {
        async function loadProduct() {
            console.log("Serial product Card", seriale);
            if (!seriale) {
                return;
            }

            const data = await getProduct(seriale);

            console.log(data);
            dispatch({
                type: ActionTypes.LoadProduct,
                payload: data.product,
            })
        }
        loadProduct();
    }, [seriale])

    return (
        <div className="flex flex-col md:flex-row gap-10 border justify-between bg-white p-8 md:px-12 md:py-8 rounded-lg border-gray-200 w-full">
            {immagine &&
                <div className="flex justify-center">
                    <img className="md:w-3/4" src={immagine} />
                </div>
            }
            <div className="flex flex-col items-start justify-center w-full md:max-w-[70%]">
                <h1 className="text-gray-900 font-bold">{modello}</h1>
                <h2 className="text-gray-900 text-[24px]">{seriale}</h2>
                <div className="flex gap-2 text-gray-400 pt-2 text-[18px]">
                    <h3 className="font-bold">{t("modelNumber")}</h3>
                    <h3>{codice_articolo}</h3>
                </div>
                <a className="product-link mt-5 text-nowrap" href="/">
                    {t("buttonLabel")}
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.83331 14.1666L14.1666 5.83325M14.1666 5.83325H5.83331M14.1666 5.83325V14.1666" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </a>
                <p className="text-gray-400">{t("underButtonLabel")}</p>
            </div>
        </div>
    )
}