import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContextProvider";
import { ActionTypes } from "../actions/actions";
import { Category } from "../enums/enums";

export function Categories() {
    const context = useContext(AppContext);
    const { docs } = context.state;
    const categories = docs.flatMap(doc => doc.category);
    const categoriesSet = new Set(categories);
    const [selectedCategory, setSelectedCategory] = useState<string>(Array.from(categoriesSet)[0]);
    const [mobile, setMobile] = useState(false)

    function handleCategoryChange(category: string) {
        setSelectedCategory(category);
    }

    // console.log(selectedCategory);
    useEffect(() => {
        const mediaQuery: MediaQueryList = window.matchMedia("(max-width: 768px)");

        const handleScreenChange = (e: MediaQueryListEvent | MediaQueryList) => {
            if (e.matches) {
                setMobile(true);
            } else {
                setMobile(false);
            }
        };
        mediaQuery.addEventListener("change", handleScreenChange);
        handleScreenChange(mediaQuery);
        return () => {
            mediaQuery.removeEventListener("change", handleScreenChange);
        };
    }, []);

    useEffect(() => {
        context.dispatch({
            type: ActionTypes.ChangeCategory,
            payload: selectedCategory
        })
    }, [selectedCategory])

    return (
        <div className="flex flex-col md:flex-row items-center w-full md:w-fit md:bg-gray-50 md:border md:border-gray-200 gap-3 md:gap-2 md:p-[6px] rounded-lg" >
            {mobile ?
                <select
                    className="select-category w-full rounded-[8px] py-3 px-4 border border-gray-300 bg-no-repeat bg-[right_.7em_top_50%] bg-[url('/public/down-arrow.svg')]"
                    onChange={(e) => handleCategoryChange(e.currentTarget.value)}
                >
                    {categoriesSet && [...categoriesSet].map((category, index) => {
                        return (
                            <option
                                key={category}
                                value={category}
                                className="flex justify-between"
                                defaultChecked={selectedCategory === category}
                            >
                                {t(`docs.categories.${category}`)}
                            </option>
                        );
                    })
                    }
                </select> : categoriesSet && [...categoriesSet].map((category, index) => {
                    return (
                        <label key={category} className="category transition-all duration-300">
                            <input
                                key={category}
                                type="radio"
                                name="categories"
                                className="hidden"
                                value={category}
                                defaultChecked={selectedCategory === category}
                                onClick={(e) => handleCategoryChange(e.currentTarget.value)}
                            />
                            {t(`docs.categories.${category}`)}
                        </label>
                    );
                })
            }
            <a href="https://google.it" className="text-primary-700 bg-transparent md:bg-primary-50 md:border md:border-primary-200 py-[10px] px-[14px] rounded-[6px] hover:bg-primary-200 transition-all duration-300">
                {t("docs.categories.documents")}
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.83331 14.1666L14.1666 5.83325M14.1666 5.83325H5.83331M14.1666 5.83325V14.1666" stroke="#1C3BD9" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </a>
        </div >
    )
}