import { Suspense, } from "react";
import "./App.css";
import WorkInProgress from "./components/WorkInProgress";
import Home from "./components/Home";
import Container from "./components/Container";
import AppContextProvider from "./context/AppContextProvider";
import { useTranslation } from "react-i18next";
import './i18n';

export default function App() {
	const { t, i18n } = useTranslation();
	return (
		<Suspense fallback="loading">
			<AppContextProvider>
				<Container>
					{process.env.REACT_APP_ENV === "dev" ?
						<Home /> :
						<WorkInProgress />}
				</Container>
			</AppContextProvider >
		</Suspense>
	)
}
