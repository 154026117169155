import { useContext, useEffect } from "react";
import { Categories } from "./Categories";
import { AppContext } from "../context/AppContextProvider";
import { ManualCard } from "./ManualCard";

export default function ManualsContainer() {
    const { state } = useContext(AppContext)
    const { docs, selectedCategory } = state;
    const filteredDocs = docs.filter(doc => selectedCategory ? doc.category.includes(selectedCategory) : "");

    return (
        <>
            <Categories />
            {filteredDocs.map(doc =>
                <ManualCard
                    key={doc.documentName}
                    {...doc}
                />
            )}
        </>
    )
}