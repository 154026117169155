import { t } from "i18next";
import { Category, DocumentLocale } from "../enums/enums";
import { useTranslation } from "react-i18next";
import { Doc } from "../@types/types";
import { useEffect, useState } from "react";

export function ManualCard(props: Doc) {
    const { t, i18n } = useTranslation();
    const { documentName, category, model, imageSrc, manualLink, downloadLink, revisions, locale, extension } = props;
    const lastRevision = revisions ? revisions.sort((a, b) => a.manualRevisionNumber.localeCompare(b.manualRevisionNumber))[0] : null;
    const lastRevisionDate = lastRevision && lastRevision.internalRevisionDate ? new Date(lastRevision.internalRevisionDate) : null;

    return (
        <div className="flex gap-4 border justify-between bg-white p-4 rounded-lg border-gray-200 md:w-[90%]">
            <div className="flex items-center w-[20%]">
                <img className="w-full" src={imageSrc} />
            </div>
            <div className="flex flex-col items-start justify-center w-[80%] gap-3">
                {category &&
                    <div className="flex gap-2">
                        {category.map(e => (
                            <div key={e} className="px-2 py-1 text-[12px] rounded-[16px] bg-gray-blue-50 border border-l-gray-blue-200">
                                {t(`docs.categories.${e}`)}
                            </div>
                        ))}
                    </div>

                }

                <a href={manualLink} className="text-primary-600 font-semibold text-left">{documentName}</a>
                <div className="text-gray-700 text-[12px]">
                    {model}
                </div>
                <div className="flex gap-3 justify-between items-center w-full">
                    <div className="flex gap-3 text-gray-400">
                        {lastRevisionDate && <span>{lastRevisionDate.toLocaleDateString(i18n.language)}</span>}
                        <span>{lastRevision?.manualRevisionNumber || ""}</span>
                        <span>{DocumentLocale[locale as unknown as keyof typeof DocumentLocale]}</span>
                        <span>{extension}</span>
                    </div>
                    <a href={downloadLink}>
                        <img src="/download.svg" />
                    </a>
                </div>
            </div>
        </div>
    )
}