import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

export function LoginBanner() {
    const { i18n, t } = useTranslation();
    const [loginKey, setLoginKey] = useState("loginExtensiveLabel.desktop")

    useEffect(() => {
        const mediaQuery: MediaQueryList = window.matchMedia("(max-width: 768px)");

        const handleScreenChange = (e: MediaQueryListEvent | MediaQueryList) => {
            if (e.matches) {
                setLoginKey("loginExtensiveLabel.mobile");
            } else {
                setLoginKey("loginExtensiveLabel.desktop");
            }
        };
        mediaQuery.addEventListener("change", handleScreenChange);
        handleScreenChange(mediaQuery);
        return () => {
            mediaQuery.removeEventListener("change", handleScreenChange);
        };
    }, []);

    return (
        <div className="w-full md:w-fit md:text-nowrap top-label flex gap-3 justify-center rounded-2xl items-center px-4 py-2 bg-primary-50 border border-primary-200 text-primary-700">
            <div>
                {t(loginKey)}
            </div>
            <div>
                <a href="/" className="login">
                    {t("loginLabel")}
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.5 9L9.5 3M9.5 3H5.5M9.5 3V7" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </a>
            </div >
        </div >
    )

}