export enum Category {
    bim_project = "BIM Project",
    certificazioni = "Certificazioni",
    conoscere_insieme = "Conoscere Insieme",
    depliant = "Depliant",
    dimensionali_dwg_pdf = "Dimensionali DWG-PDF",
    listini = "Listini",
    manuali_uso = "Manuali d'uso",
    manuali_installazion_manutenzione = "Manuali di installazione e manutenzione",
    manuali_progettisti = "Manuali progettisti",
    modulistica = "Modulistica",
    schede_tecniche = "Schede tecniche",
    sinottici = "Sinottici",
    tutorial = "Tutorial",
    video = "Video",
    detrazioni = "Detrazioni",
    brochures = "Brochures"
}

export enum DocumentLocale {
    ita_ita = "IT",
    ing_usa = "US",
    ing_uk = "EN",
    fra_fra = "FR",
    spa_spa = "ES",
    rom_rom = "RO",
}