import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContextProvider";
import { LoginBanner } from "./LoginBanner";
import { ProductCard } from "./ProductCard";
import ProductImage from "../assets/images/product.png";
import ManualsContainer from "./ManualsContainer";
import { ActionTypes } from "../actions/actions";
import { useTranslation } from "react-i18next";
import { Doc } from "../@types/types";
import { getDocs } from "../api/api";

// const image = "/images/manual.png";

// const brochure: Doc = {
//     title: "Manuale di installazione, uso e manutenzione SOLARsplit R32",
//     category: "brochures",
//     model: "SOLARsplit",
//     date: "12/12/2024",
//     revision: "R03",
//     languages: ["IT"],
//     extension: ".PDF",
//     imageSrc: image,
//     manualLink: "https://google.it",
//     downloadLink: "https://google.it"
// }

// const manual: Doc = {
//     title: "Manuale di installazione, uso e manutenzione SOLARsplit R32",
//     category: "manuali_uso",
//     model: "SOLARsplit",
//     date: "12/12/2024",
//     revision: "R03",
//     languages: ["IT"],
//     extension: ".PDF",
//     imageSrc: image,
//     manualLink: "https://google.it",
//     downloadLink: "https://google.it"
// }

// const manuals = [manual, brochure, brochure];

export default function Home() {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(AppContext);
    const { product } = state;
    const { seriale } = product;
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function loadDocs() {
            if (!seriale) {
                return;
            }

            setIsLoading(true);
            const res = await getDocs(seriale);
            setIsLoading(false);

            if (!res) {
                return;
            }

            const docs = res.data.map((doc: Doc) => doc);
            dispatch({
                type: ActionTypes.LoadDocs,
                payload: docs,
            })
        }
        loadDocs();
    }, [seriale])

    return (
        <div className="flex flex-col pt-8 md:pt-10 gap-6 md:gap-8 items-center">
            <LoginBanner />
            {isLoading ?
                <div className="flex justify-center">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-600"></div>
                </div>
                : <>
                    <ProductCard />
                    {state.docs.length > 0 ?
                        <ManualsContainer />
                        :
                        <div className="w-full md:w-fit md:text-nowrap top-label flex gap-3 justify-center rounded-2xl items-center px-4 py-2 bg-primary-50 border border-primary-200 text-primary-700">
                            <h3>{t("docs.noDocs")}</h3>
                        </div>
                    }
                </>
            }
        </div>
    );
}