import React from "react";
import Logo from "./Logo";

export default function WorkInProgress() {
    return <>
        <div className="work-in-progress">
            <div className="icon">
                <svg
                    width="26"
                    height="25"
                    viewBox="0 0 26 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5.99992 5.99992L11.2499 11.2499M5.99992 5.99992H2.49992L1.33325 2.49992L2.49992 1.33325L5.99992 2.49992V5.99992ZM21.4687 2.19777L18.4032 5.26332C17.9412 5.72534 17.7102 5.95635 17.6236 6.22273C17.5475 6.45705 17.5475 6.70945 17.6236 6.94377C17.7102 7.21016 17.9412 7.44116 18.4032 7.90318L18.68 8.17999C19.142 8.64201 19.373 8.87302 19.6394 8.95957C19.8737 9.0357 20.1261 9.0357 20.3604 8.95957C20.6268 8.87302 20.8578 8.64201 21.3199 8.17999L24.1874 5.31242C24.4963 6.06395 24.6666 6.88704 24.6666 7.74992C24.6666 11.2937 21.7937 14.1666 18.2499 14.1666C17.8227 14.1666 17.4052 14.1248 17.0013 14.0452C16.4341 13.9333 16.1506 13.8774 15.9786 13.8945C15.7959 13.9127 15.7058 13.9401 15.5438 14.0268C15.3915 14.1083 15.2387 14.2611 14.9331 14.5667L6.58325 22.9166C5.61675 23.8831 4.04975 23.8831 3.08325 22.9166C2.11676 21.9501 2.11676 20.3831 3.08325 19.4166L11.4331 11.0667C11.7387 10.7611 11.8915 10.6083 11.973 10.456C12.0597 10.294 12.0871 10.204 12.1053 10.0212C12.1224 9.84928 12.0665 9.5657 11.9547 8.99853C11.875 8.59465 11.8333 8.17716 11.8333 7.74992C11.8333 4.20609 14.7061 1.33325 18.2499 1.33325C19.423 1.33325 20.5226 1.64805 21.4687 2.19777ZM13 16.4999L19.4166 22.9165C20.3831 23.883 21.9501 23.883 22.9166 22.9165C23.8831 21.95 23.8831 20.3829 22.9166 19.4165L17.6378 14.1378C17.2641 14.1024 16.8997 14.035 16.5475 13.9383C16.0936 13.8137 15.5957 13.9041 15.2629 14.237L13 16.4999Z"
                        stroke="#3D4A67"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>
            <h1>In lavorazione</h1>
            <p>
                Non c’è nessun errore!
                <br />
                <br />
                Stiamo lavorando per implementare
                un nuovo sistema di consultazione delle informazioni per
                i nostri prodotti.
                <br />
                <br />
                Nel mentre ti invitiamo a consultare il nostro sito
            </p>
            <a href="https://www.cosmogas.com" target="_blank">
                Consulta il sito
            </a>
        </div>
    </>
}