export default function Footer() {
    return (
        <footer className="md:justify-center fixed bottom-0 w-full">
            <div>
                © Cosmogas SRL | P.IVA 00810400408 | Società a Socio unico
                soggetta a direzione e coordinamento di Clada Srl
                <a
                    href="https://www.iubenda.com/privacy-policy/42441913"
                    target="_blank"
                    className="mx-1 underline"
                >
                    Privacy
                </a>
                |
                <a
                    href="https://www.iubenda.com/privacy-policy/42441913/cookie-policy"
                    target="_blank"
                    className="mx-1 underline"
                >
                    Cookie
                </a>
            </div>
        </footer>
    );
}